import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Accordion, Table } from 'react-bootstrap';

const RevRaidReveries: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Reveries in the Rain</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_rain.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Reveries in the Rain</h1>
          <h2>Everything you need to know about the Reveries in the Rain!</h2>
          <p>
            Last updated: <strong>02/Mar/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <SectionHeader title="Introduction" />
      <p>
        Reveries in the Rain can be found in the main tab next to A Series of
        Dusks after you clear 5TH-20, “The Truth Seeker.” Reveries has 3 main
        parts:
      </p>
      <ul>
        <li>Depths of Myth</li>
        <li>Isle of Echoes</li>
        <li>Voyages of Volatility</li>
      </ul>
      <SectionHeader title="Depths of Myth" />
      <p>
        Depths of Myth is a set of permanent battles divided into 5 zones of 10
        stages each.
      </p>
      <ul>
        <li>Euphotic Zone (10m–100m)</li>
        <li>Mesophotic Zone (110m–200m)</li>
        <li>Bathyal Zone (210m–300m)</li>
        <li>Abyssal Zone (310m–400m)</li>
        <li>Hadal Zone (410m–?)</li>
      </ul>
      <p>
        As of Version 2.3, we can only clear down to 300m; the Abyssal Zone will
        be released in Version 2.5. At the end of each zone (ie. 100m, 200m,
        300m), you have to clear a Transition Zone that requires{' '}
        <strong>3 different teams of characters to clear</strong>. Completing
        these stages grants Clear Drops, Insight materials, and the new Euphoria
        materials: Keys of Thought, Sprouts of Fantasy, and Seeds of
        Inspiration.
      </p>
      <SectionHeader title="Isle of Echoes" />
      <h5>
        Starting from stage 5 (50m) of Depths of Myth, you unlock Isle of
        Echoes. This lets you clear stages to level up boss creatures (Myth
        Manifests) you can add to your team to help you clear Depths of Myth.
        Each creature has:
      </h5>
      <ul>
        <li>An Afflatus</li>
        <li>A set of synergies</li>
        <li>A Manifest skill (looks like an Ultimate)</li>
        <li>A set of passive skills</li>
        <li>A skill tree (Mind Map) you can customize as you level them up</li>
      </ul>
      <p>
        Passives have 3 stages, which unlock at Lv.1, Lv.5, and Lv.8
        respectively. These boss creatures rotate out every once in a while,
        making certain teams and characters better at clearing Reveries in the
        Rain than others.
      </p>
      <h5>Mountain Ghost (Plant)</h5>
      <p>
        <i>Rank Up, Genesis DMG, Petrify</i>
      </p>
      <StaticImage
        src="../../../images/reverse/generic/rain_1.webp"
        alt="Raid Boss"
      />
      <p>
        <strong>Works well with:</strong> Druvis III, Sotheby, Jessica, Marcus,
        Vila, Argus, Mercuria, Tuesday, Willow
      </p>
      <ul>
        <li>
          <strong>Thunderous Skies:</strong> Costs 100 Energon to cast. Mass
          attack. Deals <strong>300%</strong> Mental DMG to all enemies; gain
          Incantation Draw Limit +2 on the next round, and the top incantation
          of the Spellbox gets rank +1.
        </li>
        <li>
          <strong>Passive I:</strong> When entering battle, recover 50 Energon.
          Right before the incantations in the AP Area resolve, add a rank 3
          incantation [Coming Storm] to the spell queue, and recover Energon
          equal to the total ranks of the incantations played, multiplied by 3.
        </li>
        <ul>
          <li>
            <strong>[Coming Storm]:</strong> Deals <strong>100%</strong> Mental
            DMG to 1 enemy; inflicts 1 stack of [Weakness] on the target hit.
          </li>
          <li>
            <strong>[Weakness]:</strong> Neg Status. DMG Bonus -25% when
            attacking (reduced by 1 stack on trigger).
          </li>
        </ul>
        <li>
          <strong>Passive II:</strong> When a unit with{' '}
          <strong>[Neg Status]</strong> dies, copy 1 random{' '}
          <strong>[Neg Status]</strong> onto another random enemy.
        </li>
        <li>
          <strong>Passive III:</strong> After casting an ultimate, the 2nd and
          3rd incantations from the top of the Spellbox get rank +1.
        </li>
      </ul>
      <Accordion defaultActiveKey={['0']} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Mind Map - Major Nodes</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                Rising Mass: Starting from rounds ½ and at every 4 rounds
                thereafter, characters in positions #1/#2 gain rank +1 to a
                random incantation.
              </li>
              <li>
                Stringent Regulations III: When Mountain Ghost casts an ultimate
                the main target gets [Petrify] for 1 round.
              </li>
              <li>
                Dense Overgrowth III: When a Plant ally casts a rank ⅔
                incantation, there’s a <strong>25%</strong> chance to cast it 1
                additional time.
              </li>
              <li>
                Toxicology Research III: When Mountain Ghost attacks, copy 3
                instances of [Poison] from the main target onto a random enemy.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Mind Map - Minor Nodes</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                Stringent Regulations I: When an enemy with a [Control] status
                dies, there’s a <strong>50%</strong> chance to copy 1 random
                [Control] status onto another random enemy.
              </li>
              <li>
                Dense Overgrowth I: When a Plant ally casts a rank ⅔
                incantation, Penetration Rate <strong>+8%</strong>.
              </li>
              <li>
                Toxicology Research I: Mountain Ghost’s inflicted [Weakness] is
                converted to 2 rounds of [Poison].
              </li>
              <li>
                DMG Bonus: Plant allies gain DMG Bonus <strong>+6%</strong>.
              </li>
              <li>
                DMG Taken Reduction: Plant allies gain DMG Taken Reduction{' '}
                <strong>+6%</strong>.
              </li>
              <li>
                Sunny with Showers: After playing incantations, recover
                additional Energon equal to the total ranks of the incantations
                in the AP Area.
              </li>
              <li>
                High Voltage Field: When Mountain Ghost casts [Coming Storm],
                there’s a <strong>50%</strong> chance it will target an
                additional enemy.
              </li>
              <li>
                100% Chance of Rain: When Mountain Ghost casts [Coming Storm],
                Mental DMG dealt <strong>+50%</strong>.
              </li>
              <li>
                Acidi Haze: When Mountain Ghost casts [Coming Storm], inflict 1
                additional stack of [Weakness].
              </li>
              <li>
                Dominate Foe: Plant allies gain Stronger Afflatus Damage{' '}
                <strong>+6</strong>.
              </li>
              <li>
                Repel Foe: When a Plant ally is attacked by a non-stronger
                Afflatus, Final DMG Taken Reduction <strong>+6%</strong>.
              </li>
              <li>
                Intense Treatment I: Plant allies get skill Healing Taken{' '}
                <strong>+20%</strong> and status Healing Taken{' '}
                <strong>-20%</strong>.
              </li>
              <li>
                Hotbed Cultivation I: Plant allies get status Healing Taken{' '}
                <strong>+20%</strong> and skill Healing Taken{' '}
                <strong>-20%</strong>.
              </li>
              <li>
                Stringent Regulations II: At the end of the round, for each
                enemy that did not take an action for the round, recover 7
                Energon.
              </li>
              <li>
                Dense Overgrowth II: When a Plant ally casts a rank 2/3
                incantation, recover 3 Energon.
              </li>
              <li>
                Toxicology Research II: At the end of the round, for each
                instance of [Poison] on enemies, recover 1 Energon.
              </li>
              <li>
                Induce Crisis: When a unit with a [Neg Status] dies, copy 2
                additional random [Neg Status] effects onto another random
                enemy.
              </li>
              <li>
                Converting Excess: When Mountain Ghost casts an ultimate, the
                4th incantation from the top of the Spellbox gets rank +1.
              </li>
              <li>
                ATK: Plant allies gain ATK <strong>+3%</strong>.
              </li>
              <li>
                Intense Treatment II: When a Plant ally casts an incantation,
                recover (incantation rank x ATK x <strong>15%</strong>) HP for
                self.
              </li>
              <li>
                Hotbed Cultivation II: When a Plant ally casts an incantation,
                the ally gains [Cure] for a round duration equal to incantation
                rank.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <br />
      <h5>Star of Misfortune (Star)</h5>
      <p>
        <i>Critical, Eureka, Genesis DMG</i>
      </p>
      <StaticImage
        src="../../../images/reverse/generic/rain_2.webp"
        alt="Raid Boss"
      />
      <p>
        <strong>Works well with:</strong> Lilya, Voyager, Matilda, 37, Windsong,
        Flutterpage, Barcarola (2.4)
      </p>
      <ul>
        <li>
          <strong>Stellar Compression:</strong> Can only be cast while not in
          the Gather Energy status. Enter the Gather Energy status and cast
          [Stellar Collapse] at the end of the next round; entering the Gather
          Energy status costs no Energon, and casting [Stellar Collapse]
          consumes all Energon and enhances its effects based on the amount of
          Energon consumed.
        </li>
        <ul>
          <li>
            <strong>[Stellar Collapse]</strong>: Deals <strong>100%</strong> DMG
            to all enemies; for each [Energon] consumed, DMG{' '}
            <strong>+50%</strong>; if at least 6 [Energon] was consumed by this
            action, casts [Stellar Collapse] again with the same power.
          </li>
        </ul>
        <li>
          <strong>Passive I:</strong> When entering battle and at the start of
          every 2 rounds, Energon +1.
        </li>
        <li>
          <strong>Passive II:</strong> After casting an ultimate, gain 1 stack
          of [Discernment] for self.
        </li>
        <li>
          <strong>Passive III:</strong> This unit’s attacks are always critical
          hits.
        </li>
      </ul>
      <Accordion defaultActiveKey={['0']} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Mind Map - Major Nodes</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                Intro to Astronomy: Gain the passive skill [Insistent Beliefs]:
                Attacks a random enemy, dealing <strong>100%</strong> Mental DMG
                and gaining 1 [Energon]; this effect is casted once every 5
                rounds, and cannot be casted while gathering energy.
              </li>
              <li>
                Practical Studies III: After a Star ally performs an extra
                action, gain 1 [Energon]; at the end of the round, if 2 extra
                actions were taken in the round, gain 1 additional Energon.
              </li>
              <li>
                Flash of Eureka III: When a Star ally consumes Eureka, gain 1
                [Energon] (triggers up to 1 time per character per round).
              </li>
              <li>
                Genesis Bound III: Gain ATK <strong>+5%</strong> for self; after
                casting an ultimate, gain Energon +1.
              </li>
              <li>
                When a Star character scores a Critical Hit, gain 1 [Energon]
                (triggers up to 1 time per character per round).
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Mind Map - Minor Nodes</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                Mindset Correction: After casting an ultimate, gain 1 stack of
                [Discernment] for self.
              </li>
              <li>
                Practical Studies I: After casting an ultimate, gain 1 stack of
                [Schemed] to all allies.
              </li>
              <li>
                Penetration Rate: Star allies gain Penetration Rate{' '}
                <strong>+8%</strong>.
              </li>
              <li>
                Genesis Bonus: Star allies gain Genesis DMG{' '}
                <strong>+10%</strong>.
              </li>
              <li>
                Practical Studies II: The Ultimate is treated as an extra
                action, its Gather Energy is reduced by 1 round, but suffers DMG
                Dealt <strong>-50%</strong>.
              </li>
              <li>Expanded Thinking: Star allies gain Max Eureka +1.</li>
              <li>
                Flash of Eureka I: When a Star ally consumes Eureka, gain 1
                stack of [Lucky] for self; each ally can gain up to 10 stacks of
                [Lucky] this way. ([Lucky]: Pos Status. Critical Rate{' '}
                <strong>+5%</strong>, Critical DMG <strong>+5%</strong> (up to
                50 stacks, undispellable).)
              </li>
              <li>
                ATK: Star allies gain ATK <strong>+5%</strong>.
              </li>
              <li>
                DMG Bonus: Star allies gain DMG Bonus <strong>+6%</strong>.
              </li>
              <li>
                Flash of Eureka II: For each Eureka a Star ally consumes, the
                ally has a <strong>10%</strong> chance to recover 1 Eureka.
              </li>
              <li>
                Rapid Ideation: [Insistent Beliefs] cast frequency is changed to
                once every 4 rounds.
              </li>
              <li>
                Genesis Bound I: [Insistent Beliefs] deals an additional (own
                ATK x<strong>25%</strong>) Genesis DMG.
              </li>
              <li>
                Ultimate Might: Star allies gain Ultimate Might{' '}
                <strong>+6%</strong>.
              </li>
              <li>
                Incantation Might: Star allies gain Incantation Might{' '}
                <strong>+6%</strong>.
              </li>
              <li>
                Genesis Bound II: The ultimate deals an additional (own ATK x
                <strong>80%</strong>) Genesis DMG.
              </li>
              <li>
                Reflective Rumination: [Insistent Beliefs] inflicts an
                additional stack of [Confusion] on a random target.
                ([Confusion]: Neg Status. Critical Resist <strong>-25%</strong>{' '}
                (can stack; each stack is timed independently).)
              </li>
              <li>
                Sudden Creativity I: At the start of the round,{' '}
                <strong>50%</strong> chance to inflict 1 stack of [Confusion] on
                a random target for 1 round.
              </li>
              <li>
                Critical Rate: Star allies gain Critical Rate{' '}
                <strong>+8%</strong>.
              </li>
              <li>
                Critical DMG: Star allies gain Critical DMG{' '}
                <strong>+10%</strong>.
              </li>
              <li>
                Sudden Creativity II: After casting an ultimate, grant 2 stacks
                of [Discernment] to all allies. ([Discernment]: Pos Status.
                Critical DMG <strong>+40%</strong> (-1 stack when scoring a
                critical hit with an incantation).)
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <br />
      <h5>Operatic Reflection (Beast)</h5>
      <p>
        <i>Burn, Extra Action, Moxie</i>
      </p>
      <StaticImage
        src="../../../images/reverse/generic/rain_3.webp"
        alt="Raid Boss"
      />
      <p>
        <strong>Works well with:</strong> Spathodea, Isolde, J, Anjo Nala,
        Lopera
      </p>
      <ul>
        <li>
          <strong>Wonders Incoming I :</strong> Costs 25 Heat to cast. 1-target
          attack, deals <strong>200%</strong> Mental DMG; enhances to [Butterfly
          Dream I] at 50 Heat.
        </li>
        <ul>
          <li>
            <strong>[Butterfly Dream I]:</strong> Costs 50 Heat to cast.
            1-target attack, deals <strong>200%</strong> Mental DMG; inflicts 1
            stack of [Noise] after attacking; enhances to [Withered Garden I] at
            100 Heat.
          </li>
          <li>
            <strong>[Noise]:</strong> Neg Status. After being attacked, the
            attacker gains Moxie +1 (removed after trigger).
          </li>
          <li>
            <strong>[Withered Garden I]:</strong> Costs 100 Heat to cast.
            1-target attack, deals <strong>500%</strong> Mental DMG; after
            attacking, inflicts 2 stacks of [Noise] on the target.
          </li>
        </ul>
        <li>
          <strong>Passive I:</strong> At the end of the round, gain 20 Heat. At
          the start of the round, if a cumulative total of 100 Heat has been
          consumed, evolve into the next form with active skills [Wonders
          Incoming II], [Butterfly Dream II], and [Withered Garden II].
        </li>
        <ul>
          <li>
            <strong>[Wonders Incoming II]:</strong> Costs 25 Heat to cast. Mass
            attack, deals <strong>200%</strong> Mental DMG to all enemies;
            enhances to [Butterfly Dream II] at 50 Heat.
          </li>
          <li>
            <strong>[Butterfly Dream I]:</strong> Costs 50 Heat to cast. Mass
            attack, deals <strong>200%</strong> Mental DMG to all enemies;
            inflicts 1 stack of [Noise] on the main target; enhances to
            [Withered Garden II] at 100 Heat.
          </li>
          <li>
            <strong>[Withered Garden I]:</strong> Costs 100 Heat to cast. Mass
            attack, deals <strong>500%</strong> Mental DMG to all enemies and
            inflicts 2 stacks of [Noise] on the main target.
          </li>
        </ul>
        <li>
          <strong>Passive II:</strong> At the start of the round, casts
          [Harmonic Accompaniment].
        </li>
        <li>
          <strong>Passive III:</strong> For every 1 Heat consumed in forms
          1/2/3, allies recover <strong>0.3%/0.4%/0.5%</strong> of Missing HP.
        </li>
      </ul>
      <Accordion defaultActiveKey={['0']} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Mind Map - Major Nodes</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                DMG Bonus: Beast allies gain DMG Bonus <strong>+6%</strong>.
              </li>
              <li>
                Singer in Lead III: Heat Limit +100. Active incantations can
                grant up to 4 Heat. Consume 200 Heat to deploy the array
                [Perpetual Flames], and gain 50 Heat after cast. ([Perpetual
                Flames]: [Array] Effect: Enemy [Burn] stacks will not decrease
                on resolve. When an ally takes an action, the excess Burn stacks
                they inflict grant an equal number of [Preignition] stacks,
                stacks up to 10/15/20 (during forms 1/2/3).
              </li>
              <li>
                Conducting Maestra III: When a Beast ally attacks, for each
                stack of Burn on the target, the attack gains Penetration Rate{' '}
                <strong>+1%</strong>.
              </li>
              <li>
                Harmonic Notes: When a Beast ally attacks, for each stack of
                Burn on the target, the attack gains Leech Rate{' '}
                <strong>+0.5%</strong>; if the attack is an extra action, gain
                an additional Leech Rate <strong>+10%</strong>.
              </li>
              <li>
                Accompanyist III: When a Beast ally attacks with an extra
                action, Multiplier <strong>+25%</strong>.
              </li>
              <li>
                Virtuosity III: When another ally performs an extra action, the
                ally gains Moxie +1 (triggers up to 2 times per round).
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Mind Map - Minor Nodes</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                Singer in Lead I: Active incantations deal an additional{' '}
                <strong>100%</strong> Mental DMG.
              </li>
              <li>
                Forceful Notes: [Harmonic Accompaniment] deals an additional{' '}
                <strong>20%/40%/60%</strong> Mental DMG when in forms 1/2/3.
              </li>
              <li>
                ATK: Beast allies gain ATK <strong>+3%</strong>.
              </li>
              <li>Scorching Reserves: When entering battle, Heat +30.</li>
              <li>
                Energizing Tunes: At the end of the round, gain an additional
                Heat +5.
              </li>
              <li>
                Critical Rate: Beast allies gain Critical Rate{' '}
                <strong>+8%</strong>.
              </li>
              <li>
                Singer in Lead II: After casting an active skill, all allies
                gain a Shield with (Max HP x<strong>15%</strong>) HP for 1
                round.
              </li>
              <li>
                Conducting Maestra I: Active incantations inflict 5 additional
                stacks of Burn on the target.
              </li>
              <li>
                Spontaneous Shift: Active incantations no longer inflict [Noise]
                and inflicts 20 stacks of [Burn] instead.
              </li>
              <li>
                Intense Fervor: [Harmonic Accompaniment] inflicts 5 additional
                stacks of [Burn] on the target.
              </li>
              <li>
                Melodic Variation: Heat no longer recovers at the end of the
                round. For every 2 stacks of [Burn] on enemies, gain Heat +1; if
                only 1 enemy is present, gain an additional Heat +10.
              </li>
              <li>
                DMG Bonus: Beast allies gain DMG Bonus <strong>+6%</strong>.
              </li>
              <li>
                Conducting Maestra II: When entering phase 2/3, the Burn stack
                limit for enemies is increased by 10/20.
              </li>
              <li>
                Residual Heat: When consuming Heat, [Passive III]’s HP recovery
                is increased by <strong>+100%</strong>.
              </li>
              <li>
                Ultimate Might: Beast allies gain Ultimate Might{' '}
                <strong>+12%</strong>.
              </li>
              <li>
                Rondo Composition: When another ally performs an extra action,
                Heat +3 (can trigger up to 5 times per round).
              </li>
              <li>
                Penetration Rate: Beast allies gain Penetration Rate{' '}
                <strong>+8%</strong>.
              </li>
              <li>
                Accompanyist I: Active incantations inflict an additional [Weak
                Spot] on the main target for 2 rounds. ([Weak Spot]: Special.
                When being attacked by an extra action, DMG Taken{' '}
                <strong>+25%</strong>.)
              </li>
              <li>
                Accompanyist II: When a Beast ally performs an extra action,
                Penetration Rate <strong>+20%</strong>.
              </li>
              <li>
                DMG Taken Reduction: Beast allies gain DMG Taken Reduction{' '}
                <strong>+6%</strong>.
              </li>
              <li>
                Musical Collaboration: Whenever after another ally performs an
                extra action, [Harmonic Accompaniment] gains Penetration Rate{' '}
                <strong>+10%</strong> on the next attack.
              </li>
              <li>
                Echo: Whenever after a Beast ally performs an extra action, the
                unit gains a stackable DMG Taken Reduction <strong>+10%</strong>{' '}
                for 1 round.
              </li>
              <li>
                Incantation Might: Beast allies gain Incantation Might{' '}
                <strong>+12%</strong>.
              </li>
              <li>
                Virtuosity I: Active skills inflict 1 additional stack of [Burn]
                on the main target.
              </li>
              <li>
                Virtuosity II: When entering forms 2/3, allies gain Max Moxie
                +1/2.
              </li>
              <li>
                Critical Rate: Beast allies gain Critical Rate{' '}
                <strong>+8%</strong>.
              </li>
              <li>
                Lone Performance: [Harmonic Accompaniment]’s damage is greatly
                reduced; grant Moxie +2 to the ally with the lowest Moxie.
              </li>
              <li>Sonata Form: Whenever an ally gains Moxie, Heat +1.</li>
              <li>
                Penetration Rate: Beast allies gain Penetration Rate{' '}
                <strong>+8%</strong>.
              </li>
              <li>
                Rousing Symphony: After a Beast ally casts an ultimate, all
                allies gain 1 stack of [Attentive Listening]. ([Attentive
                Listening]: Pos Status. Leech Rate <strong>+2%</strong> (stacks
                up to 10 times).)
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <SectionHeader title="Sneak Peek at future Myth Manifests" />
      <p>
        <i>
          Note: A special thank you to ayasenecromancer on Discord for sharing
          this information. As we’ve translated this ourselves, please let us
          know if there are any errors.
        </i>
      </p>
      <h5>Ashen Beast (Mineral)</h5>
      <p>
        <i>Blood Burn, Extra Action, Buff</i>
      </p>
      <StaticImage
        src="../../../images/reverse/generic/rain_4.webp"
        alt="Raid Boss"
      />
      <p>
        <strong>Works well with:</strong> Eternity, Ms. NewBabel, Pickles, Jiu
        Niangzi, Semmelweis, J, Fatutu (2.4)
      </p>
      <ul>
        <li>
          <strong>Twist the Ashen Haze :</strong> Mass attack. Depletes{' '}
          <strong>50%</strong> Smoke Concentration and deals{' '}
          <strong>150%</strong> Reality DMG to all enemies. If cast again within
          the same round, this skill enhances to [Swallow the Sunlight’s
          Colors].
        </li>
        <ul>
          <li>
            <strong>[Swallow the Sunlight’s Colors]:</strong> Mass attack.
            Depletes <strong>50%</strong> Smoke Concentration and deals{' '}
            <strong>150%</strong> Reality DMG to all enemies. All allies gain 1
            stack of [Sturdiness]. If cast again within the same round, this
            skill enhances to [Mourn the Sunlight’s Burial].
          </li>
          <li>
            <strong>[Mourn the Sunlight’s Burial]:</strong> Mass attack. Deals{' '}
            <strong>300%</strong> Reality DMG to all enemies and grants 1 stack
            of [Rousing Morale] to all allies. The Ashen Beast enters [Overheat]
            for 3 rounds.
          </li>
          <li>
            <strong>[Overheat]:</strong> Special. Cannot act. Cannot increase
            Smoke Concentration.
          </li>
        </ul>
        <li>
          <strong>Passive I:</strong> At the start of the round, Smoke
          Concentration increases by <strong>25%</strong>.
        </li>
        <li>
          <strong>Passive II:</strong> At the end of the round, all allies
          recover <strong>10%</strong> Lost HP.
        </li>
        <li>
          <strong>Passive III:</strong> After actively casting 4 incantations,
          dispel all allies’ [Stats Down] and [Control].
        </li>
      </ul>
      <Accordion defaultActiveKey={['0']} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Mind Map - Major Nodes</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>Mineral allies gain DMG Taken Reduction.</li>
              <li>
                Pyroclastic Ash III: [Overheat] duration +1 round. When
                [Overheat] ends, Smoke Concentration increases by{' '}
                <strong>50%</strong>.
              </li>
              <li>
                Torch Sacrifice III: Mineral allies gain Penetration Rate{' '}
                <strong>+3%</strong> when attacking for every{' '}
                <strong>5%</strong> Lost HP.
              </li>
              <li>Visible Winds III: [Heavy Fog] duration +10 rounds.</li>
              <li>
                Warm Space III: After casting [Mourn the Sunlight’s Burial], all
                allies gain [Fogborne Fate] for 3 rounds. ([Fogborne Fate]:
                Special. At the end of the round, if HP is above{' '}
                <strong>50%</strong>, lose <strong>15%</strong> Current HP to
                gain a shield equal to <strong>200%</strong> of the HP lost; if
                HP is below <strong>50%</strong>, gain a shield equal to ATK *{' '}
                <strong>200%</strong> (shields gained from this effect last 1
                round and reduce DMG Taken by <strong>20%</strong>).)
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Mind Map - Minor Nodes</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                Pyroclastic Ash I: Actively-cast incantations deal{' '}
                <strong>50%</strong> additional Reality DMG.
              </li>
              <li>
                DMG Reduction: Mineral allies gain DMG Taken Reduction{' '}
                <strong>+6%</strong>.
              </li>
              <li>
                Sunshine No More: When entering battle, Smoke Concentration
                increases by 50%.
              </li>
              <li>
                Pyroclastic Ash II: When casting [Mourn the Sunlight’s Burial],
                dispels all allies’ [Neg Status] and [Control].
              </li>
              <li>
                Embers: At the end of the round, recovers all allies’ HP by 5%
                Max HP.
              </li>
              <li>
                Burning Stars: During [Overheat], own DMG increases by{' '}
                <strong>10%</strong> at the start of each round.
              </li>
              <li>
                Seeping Stars: During [Overheat], dispels all [Stats Down] at
                the start of each round.
              </li>

              <li>
                Torch Sacrifice I: When actively casting an incantation, all
                allies lose <strong>10%</strong> Current HP; this attack
                additionally deals all allies’ Lost HP * <strong>15%</strong>{' '}
                Genesis DMG.
              </li>
              <li>
                DMG Bonus: Mineral allies gain DMG Bonus <strong>+6%</strong>.
              </li>
              <li>
                Embodied Inferno: When entering battle, all allies lose{' '}
                <strong>30%</strong> Current HP. At the start of the round,
                Smoke Concentration increases by <strong>5%</strong> for every{' '}
                <strong>5%</strong> Lost HP among all allies (up to 25%).
              </li>
              <li>
                Warning Flare: When entering [Overheat], all allies gain a
                shield equal to <strong>30%</strong> Lost HP for 3 rounds.
              </li>
              <li>
                Add Firewood: After being attacked, Mineral allies below{' '}
                <strong>50%</strong> HP recover <strong>25%</strong> Lost HP (up
                to 3 times per character).
              </li>
              <li>
                Carbonization: At the end of the round, Mineral allies below{' '}
                <strong>50%</strong> HP gain 1 stack of [Sturdiness].
              </li>
              <li>
                Torch Sacrifice II: When [Overheat] ends, consumes{' '}
                <strong>30%</strong> of all allies’ Current HP and gains{' '}
                <strong>50%</strong> Smoke Concentration.
              </li>

              <li>
                Visible Winds I: After actively casting an incantation, all
                allies gain 1 stack of [Heavy Fog] for 2 rounds. ([Heavy Fog]:
                Pos Status. Extra action Incantation Might <strong>+10%</strong>{' '}
                and Penetration Rate <strong>+10%</strong> (stacks up to 5
                times).)
              </li>
              <li>
                Penetration Rate: Mineral allies gain Penetration Rate{' '}
                <strong>+8%</strong>.
              </li>
              <li>
                Fan the Flames: When allies perform an extra action, Smoke
                Concentration increases by 10% (up to 3 times per round).
              </li>
              <li>
                Visible Winds II: Mineral allies recover 10% Max HP after
                performing an extra action (up to once per round per character).
              </li>
              <li>
                All Together: After actively casting 2 incantations, if the
                Ashen Beast is not in [Overheat], casts [Bite].
              </li>
              <li>
                Wildfire: At the end of every 2 rounds, if the Ashen Beast is
                not in [Overheat], casts [Bite] on a random enemy. ([Bite]:
                1-target attack. Deals 100% DMG.)
              </li>
              <li>
                Wolf Fang: [Bite] additionally inflicts 1 stack of [Exposed
                Weakness] on the target.
              </li>

              <li>
                Warm Space I: After actively casting an incantation, grants all
                allies 1 random [Ashen Collection of Buffs] effect for 2 rounds.
              </li>
              <li>
                ATK: Mineral allies gain ATK <strong>+3%</strong>.
              </li>
              <li>
                Amassing: At the start of the round, for every 2 [Stats Up],
                [Pos Status], [Counter], [Shield], and [Channel] among all
                allies, Smoke Concentration <strong>+5%</strong>, up to{' '}
                <strong>+25%</strong>.
              </li>
              <li>
                Enduring: [Stats Up] granted by the Ashen Beast last +1 round.
              </li>
              <li>
                Warm Space II: At the start of the round, additionally grants
                all allies 1 random [Ashen Collection of Buffs] effect for 2
                rounds.
              </li>
              <li>
                Dispersing: When Mineral allies die, they recover{' '}
                <strong>1%</strong> Max HP (up to once per character).
              </li>
              <li>
                Miasma: When Mineral allies have 4 or more types of [Stats Up],
                DMG Taken Reduction <strong>+12%</strong>.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <br />
      <h5>Primitive Urge (Spirit)</h5>
      <p>
        <i>Ultimate</i>
      </p>
      <StaticImage
        src="../../../images/reverse/generic/rain_5.webp"
        alt="Raid Boss"
      />
      <p>
        <strong>Works well with:</strong> Melania, Shamane, Anjo Nala, Noire
        (2.5), Recoleta (2.6)
      </p>
      <ul>
        <li>
          <strong>Sink into the Dreamscape :</strong> Consumes all Energon (at
          least 3) and creates the array [Crimson Dreamscape] for 2 rounds. For
          each Energon depleted, [Crimson Dreamscape]’s duration increases by 1
          round. When the array ends, Primitive Urge casts the ultimate [Fall
          into the Abyss].
        </li>
        <ul>
          <li>
            <strong>[Crimson Dreamscape]:</strong> [Array] effect: Allies’
            Ultimate Might <strong>+30%</strong>. At the start of the round, all
            enemies gain 1 [Collection of Debuffs] effect for 2 rounds and ATK{' '}
            <strong>-5%</strong>; the Primitive Urge’s ATK increases by the same
            amount.
          </li>
          <li>
            <strong>[Collection of Debuffs]:</strong> 7 effects in total.
            [Blind], [Nasty Wound], [Mis-aim], [Weakness], [Scared],
            [Confusion], [Fracture I]. Prioritizes effects not yet applied.
          </li>
        </ul>
        <li>
          <strong>Passive I:</strong> When allies gain 5 Moxie, Energon +1.
          Temporarily stores allies’ excess Moxie (up to 2); at the end of the
          round, if the ally has space for that Moxie, it is replenished (Moxie
          is temporarily stored based on whose is highest, does not stack).
        </li>
        <li>
          <strong>Passive II:</strong> While in the [Crimson Dreamscape] array,
          casts [Tender Embrace] as a follow-up attack after allies cast a
          damaging ultimate.
        </li>
        <ul>
          <li>
            <strong>[Tender Embrace]:</strong> 1-target attack. Deals{' '}
            <strong>100%</strong> Mental DMG and Moxie -1 for the target.
          </li>
        </ul>
        <li>
          <strong>Passive III:</strong> After allies cast an ultimate, all
          allies recover <strong>10%</strong> Lost HP.
        </li>
      </ul>
      <Accordion defaultActiveKey={['0']} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Mind Map - Major Nodes</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                Ultimate Might: All allies gain Ultimate Might{' '}
                <strong>+12%</strong>.
              </li>
              <li>
                Lost in Obsession: Ultimate Might <strong>+20%</strong>, Max
                Energon -2, Array cooldown -2 turns, Energon +1 when the Array
                ends.
              </li>
              <li>
                Surviving Somniloquy: After allies cast a damage-dealing
                Ultimate, the Primitive Urge casts [Licking Thorns] as a
                follow-up attack even while not in the Array (no longer casts
                [Tender Embrace].) ([Licking Thorns]: 1-target attack. Deals{' '}
                <strong>200%</strong> Mental DMG.)
              </li>
              <li>
                Never-ending Feast: While [Crimson Dreamscape] is active,
                Ultimates consume 1 fewer Moxie.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Mind Map - Minor Nodes</Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                Enter the Dream: When [Crimson Dreamscape] is created, the enemy
                with the highest Moxie loses 1 Moxie.
              </li>
              <li>
                Drained: When [Crimson Dreamscape] ends, inflicts [Seal] on all
                enemies for 1 turn.
              </li>
              <li>
                Ultimate Might: All allies gain Ultimate Might{' '}
                <strong>+6%</strong>.
              </li>
              <li>
                Enter the Dream II: When [Crimson Dreamscape] is created, all
                allies gain [Moxie Guard] for 1 round.
              </li>
              <li>
                Ultimate Might: All allies gain Ultimate Might{' '}
                <strong>+6%</strong>.
              </li>
              <li>
                Past Memories: [Fall into the Abyss] deals <strong>-50%</strong>{' '}
                Mental DMG, but the Primitive Urge regains 1 Energon immediately
                when the Array ends. ([Fall into the Abyss]: Mass attack. Deals{' '}
                <strong>300%</strong> Mental DMG to all enemies and consumes all
                Energon; this increases by <strong>100%</strong> Mental DMG for
                each Energon consumed.)
              </li>
              <li>
                Sucking Nutrients III: When allies lose Moxie, they recover{' '}
                <strong>10%</strong> Max HP.
              </li>
              <li>
                The Price of Falling: [Fall into the Abyss] additionally
                inflicts 2 stacks of [Weakness] on the target. ([Weakness]: Neg
                Status. When attacking, DMG Dealt <strong>-25%</strong> (-1
                stack after trigger).
              </li>
              <li>When allies enter battle, Moxie +1.</li>
              <li>Restless: Additionally store 1 more excess Moxie.</li>
              <li>
                A Dream’s Aftertaste: After casting [Fall into the Abyss], the
                Primitive Urge’s Ultimate Might <strong>+10%</strong> (can
                stack).
              </li>
              <li>Sucking Nutrients: When entering battle, Energon +1.</li>
              <li>
                Extravagant Feast II: While [Crimson Dreamscape] is active, all
                enemies’ ATK <strong>-5%</strong> (the Primitive Urge’s ATK
                increases by the same amount).
              </li>
              <li>
                Ultimate Might: All allies gain Ultimate Might{' '}
                <strong>+6%</strong>.
              </li>
              <li>
                Deep Embrace: [Tender Embrace] deals <strong>+50%</strong>{' '}
                Mental DMG.
              </li>
              <li>
                Extravagant Feast III: While [Crimson Dreamscape] is active, all
                allies’ Ultimate DMG Taken <strong>-20%</strong>.
              </li>
              <li>
                Peaceful: While [Crimson Dreamscape] is active, all allies gain
                1 stack of [Agitation]. ([Agitation]: Pos Status. Ultimate Might{' '}
                <strong>+5%</strong>, stacks up to 10 times (undispellable).)
              </li>
              <li>
                Stare: When [Fall into the Abyss] is cast, if there are fewer
                than 3 enemies, Mental DMG increases by <strong>75%</strong> for
                each missing enemy.
              </li>
              <li>
                Ultimate Might: All allies gain Ultimate Might{' '}
                <strong>+6%</strong>.
              </li>
              <li>
                Sucking Nutrients II: During [Crimson Dreamscape], after an ally
                casts an Ultimate, Moxie +1 for the ally with the lowest Moxie.
              </li>
              <li>
                Extravagant Feast: When allies are in [Crimson Dreamscape], they
                gain Ultimate Might <strong>+10%</strong> when attacking.
              </li>
              <li>
                A Long Dream: When consuming 4 or more Energon to create the
                Array, the Array’s duration increases by 1 round.
              </li>
              <li>
                Wake from the Dream: At the end of the round, if the Primitive
                Urge has 5 Energon, the Array immediately ends.
              </li>
              <li>
                Thanks for the Hospitality: While [Crimson Dreamscape] is
                active, every allied Ultimate cast increases the damage of the
                next [Fall into the Abyss] by <strong>100%</strong> Mental DMG
                and increases the duration of enemy [Neg Status] effects by 1
                round.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <SectionHeader title="Voyages of Volatility" />
      <p>
        Voyages of Volatility is the new challenge mode that refreshes
        periodically (like Limbo). There are{' '}
        <strong>
          3 different stages that require completely different teams
        </strong>{' '}
        (including Myth Manifests) to clear. Try to defeat all enemies in the
        turn limit to score points. Each stage has 3 difficulties to choose
        from; the harder the stage, the higher your score multiplier.
      </p>
      <p>Complete this game mode to get the following rewards:</p>

      <Table striped bordered responsive>
        <thead>
          <tr>
            <th>Requirement</th>
            <th>Rewards</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Reach 30000 total points</td>
            <td>
              1 Key of Thought, 4 Sprouts of Fantasy, 80 Seeds of Inspiration
            </td>
          </tr>
          <tr>
            <td>Reach 40000 total points</td>
            <td>
              1 Key of Thought, 4 Sprouts of Fantasy, 80 Seeds of Inspiration
            </td>
          </tr>
          <tr>
            <td>Reach 50000 total points</td>
            <td>
              1 Key of Thought, 4 Sprouts of Fantasy, 80 Seeds of Inspiration
            </td>
          </tr>
          <tr>
            <td>Reach 60000 total points</td>
            <td>
              1 Key of Thought, 3 Sprouts of Fantasy, 80 Seeds of Inspiration
            </td>
          </tr>
          <tr>
            <td>Reach 80000 total points</td>
            <td>4 Fine Insight Packages, 20000 Sharpodonty</td>
          </tr>
        </tbody>
      </Table>
      <p>
        The maximum number of points you can get in each stage is{' '}
        <strong>40000</strong> (i.e. clearing all enemies in Hard difficulty).{' '}
        <strong>To get the most important rewards</strong> (at 60000 points),{' '}
        <strong>
          you can get away with clearing every stage on Normal difficulty.
        </strong>
      </p>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevRaidReveries;

export const Head: React.FC = () => (
  <Seo
    title="Reveries in the Rain | Reverse: 1999 | Prydwen Institute"
    description="Everything you need to know about the Reveries in the Rain!"
    game="reverse"
  />
);
